<template>
  <div class="recruit">
    <Crumbs></Crumbs>
    <el-row class="recruit-top">
      <div class="recruit-top-left">
        <span class="day">日期选择：</span>
        <el-date-picker
          v-model="value"
          type="month"
          placeholder="选择日期"
          @change="dayChange"
          :clearable="false"
        >
        </el-date-picker>
      </div>
      <div class="recruit-top-right">
        <el-button
          class="recruit-top-btnxz"
          size="small"
          type="primary"
          @click="dialogFormVisible = true"
          >新增</el-button
        >
        <el-button class="recruit-top-btndc" size="small" type="primary" @click="downloadExcel"
          >导出</el-button
        >
      </div>
    </el-row>
    <el-row class="recruit-btm" :class="tableData.length > 0 ? 'a' : ''">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        max-height="620"
        :span-method="objectSpanMethod"
      >
        <el-table-column
          align="center"
          :label="tableData.length > 0 ? '' : '招聘情况分析 '"
          width="150"
        >
          <div style="margin-bottom: 55px">招聘情况分析</div>
        </el-table-column>
        <el-table-column align="center" prop="department" label="部门"> </el-table-column>
        <el-table-column align="center" prop="kqgws" label="空缺岗位数(本月目标岗位数)">
        </el-table-column>
        <el-table-column align="center" prop="jlsxs" label="简历筛选数"> </el-table-column>
        <el-table-column align="center" prop="csrs" label="初试参加人数"> </el-table-column>
        <el-table-column align="center" prop="fsrs" label="复试参加人数"> </el-table-column>
        <el-table-column align="center" prop="fstgrs" label="复试通过人数"> </el-table-column>
        <el-table-column align="center" prop="dgrs" label="到岗人数"> </el-table-column>
        <el-table-column
          align="center"
          prop="lyb"
          :formatter="batteryformatter"
          label="录用比"
          width="130"
        >
        </el-table-column>
        <el-table-column
          fixed="right"
          align="center"
          style="background-color: black"
          label="操作"
          width="100"
        >
          <template slot-scope="scope">
            <el-button @click="deitRow(scope.row)" class="text_Edit_Bgc" type="text" size="small">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="pageChangeHandler"
        @size-change="handleSizeChange"
        :current-page="recruitInfo.pageNow"
        :page-size="recruitInfo.pageSize"
        prev-text="上一页"
        next-text="下一页"
        :total="recruitInfo.total"
      >
      </el-pagination>
    </el-row>
    <DiaLog ref="DiaLog" :dialogFormVisible.sync="dialogFormVisible" @update="updateDate"></DiaLog>
  </div>
</template>

<script>
export default {
  components: {
    DiaLog: () => import('./components/DiaLog.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      recruitInfo: {
        department: '',
        csrs: 0,
        dgrs: 0,
        fsrs: 0,
        fstgrs: 0,
        jlsxs: 0,
        kqgws: 0,
        lyb: '',
        submitDate: '',
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      value: this.monthStartTimestamp(),
      tableData: [],
      dialogFormVisible: false,
    }
  },
  created() {
    this.recruitInfo.submitDate = this.monthStartTimestamp()
    this.getData()
  },
  methods: {
    //获得本月开始时间戳
    monthStartTimestamp() {
      let now = new Date()
      let nowYear = now.getFullYear() //当前年
      let nowMonth = now.getMonth() //当前月
      let firstDay = new Date(nowYear, nowMonth, 1).getTime() //本月开始时间戳
      return firstDay
    },

    //弹窗时间更新
    async updateDate() {
      if (this.recruitInfo.submitDate !== '') {
        this.getData()
      } else {
        this.recruitInfo.submitDate = ''
        this.getData()
      }
    },

    //修改
    deitRow(row) {
      this.$refs.DiaLog.Echo(row)
      this.dialogFormVisible = true
    },

    // 录用比加百分号展示
    batteryformatter(row) {
      return Number(row.lyb) + '%'
    },

    //改变搜索时间
    dayChange(value) {
      if (value == null) {
        value = new Date()
        this.recruitInfo.submitDate = ''
        this.getData()
      } else {
        let date = new Date(value)
        let time = date.getTime()
        this.recruitInfo.submitDate = time
        this.getData()
      }
    },

    //获取数据
    async getData() {
      const res = await this.$api.user.getRecruitmentInfo(this.recruitInfo)
      this.reformData(res.data.records)
      this.tableData = res.data.records
      this.recruitInfo.total = this.tableData.length
    },
    reformData(list) {
      if (!list) {
        let csrsAll = 0
        let dgrsAll = 0
        let fsrsAll = 0
        let fstgrsAll = 0
        let jlsxsAll = 0
        let kqgwsAll = 0
        let lybAll = 0
        list.forEach(v => {
          csrsAll += v.csrs
          dgrsAll += v.dgrs
          fsrsAll += v.fsrs
          fstgrsAll += v.fstgrs
          jlsxsAll += v.jlsxs
          kqgwsAll += v.kqgws
        })
        let Obj = {
          department: '合计',
          csrs: csrsAll,
          dgrs: dgrsAll,
          fsrs: fsrsAll,
          fstgrs: fstgrsAll,
          jlsxs: jlsxsAll,
          kqgws: kqgwsAll,
          lyb: lybAll,
        }
        list.push(Obj)
      }
    },

    // 换页
    pageChangeHandler(val) {
      this.recruitInfo.pageNow = val
      this.getData()
    },

    // 换页面大小
    handleSizeChange(val) {
      this.recruitInfo.pageSize = val
      this.recruitInfo.pageNow = 1
      this.getData()
    },

    //导出
    downloadExcel() {
      this.$api.user
        .exportRecruitmentInfo({ submitDate: this.recruitInfo.submitDate })
        .then(res => {
          var blob = new Blob([res], { type: 'application/vnd.ms-excel' })
          var url = URL.createObjectURL(blob)
          var exportLink = document.createElement('a')
          exportLink.setAttribute('download', '招聘情况分析.xlsx')
          exportLink.href = url
          document.body.appendChild(exportLink)
          exportLink.click()
          document.body.removeChild(exportLink) //下载完成移除元素
          URL.revokeObjectURL(exportLink.href) //释放掉blob对象
          this.$message({
            message: '导出成功!',
            type: 'success',
          })
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 增大第一列span
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: 99,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.recruit {
  padding: 10px;

  .recruit-top {
    .recruit-top-left {
      margin: 5px;
      height: 40px;

      .day {
        font-size: 20px;
        margin-right: 20px;
      }
    }

    .recruit-top-right {
      position: relative;

      .recruit-top-btnxz {
        position: absolute;
        right: 130px;
        top: -35px;
        width: 90px;
      }

      .recruit-top-btndc {
        position: absolute;
        right: 8px;
        top: -35px;
        width: 90px;
      }
    }
  }

  .recruit-btm {
    margin-top: 20px;

    .el-pagination {
      text-align: right;

      margin-top: 20px;
    }
  }
}

/deep/.el-table th.el-table__cell {
  background: #dce6f1;
}

/deep/.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf,
/deep/.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  color: #0f1010;
}

/deep/.el-table--border {
  border: 1px solid black;
}

/deep/.el-table th.el-table__cell:first-of-type {
  background-color: #fde9d9;
}

/deep/ .a .el-table th.el-table__cell:first-of-type {
  border-bottom: 1px solid #fde9d9;
}

/deep/.el-table__row:first-of-type td:first-of-type,
/deep/.el-table__row:first-of-type td:first-of-type:hover > td.el-table__cell {
  background-color: #fde9d9 !important;
  border-top: 1px solid #fde9d9 !important;
}
</style>
<style>
.el-month-table td.today .cell {
  font-weight: 500;
  color: #606266;
}
</style>
